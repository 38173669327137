<template>
  <v-container fluid>
    <candidate-tabs />
    <div class="profile--view--card" v-if="dataForProfileView.length >= 1">
      <div v-for="(candidate, index) in dataForProfileView" :key="index">
        <profile-view-card :candidate="candidate" />
      </div>
    </div>
    <v-row style="font-size: 20px; text-align: center" v-else>
      <no-candidates></no-candidates>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import CandidateTabs from "../components/CandidatesStatusTabs.vue";
import ProfileViewCard from "../components/ProfileViewCard.vue";
import NoCandidates from "../components/NoCandidates/NoCandidates.vue";

export default {
  components: { ProfileViewCard, CandidateTabs, NoCandidates },
  name: "ProfileView",
  data: () => ({
    pagination: {
      page: 1,
      perPage: 25,
    },
    candidateRadio: "AllCandidates",
    data: [],
  }),
  computed: {
    ...mapGetters(["dataForProfileView", "candidatesStatusActiveTab"]),
    maxCandidatesVisible() {
      return this.pagination.page * this.pagination.perPage;
    },
    minCandidatesVisible() {
      if (this.pagination.page == 1) {
        return 0;
      }
      return this.maxCandidatesVisible - this.pagination.perPage;
    },
  },
  methods: {
    ...mapActions(["fetchCandidates", "fetchCandidates2"]),
    loadCandidates() {
      this.fetchCandidates(this.$route.params.id || this.defaultCampaign);
      this.fetchCandidates2(this.$route.params.id || this.defaultCampaign);
    },
  },
};
</script>

<style lang="scss">
.profile--view--card div:first-child {
  margin-bottom: 5px;
}
</style>
